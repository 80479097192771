<template>
  <!-- 应产品要求，进入系统显示空白页 2023.8.1 -->
  <div style="display: flex; align-items: center; justify-content: center;">
    请在左侧菜单选择需要使用的功能
  </div>
  <!-- <a-row :gutter="[0, 20]" style="min-width: 900px">
    <a-card>
      <a-row type="flex">
        <a-col>
          <a-list-item>
            <a-list-item-meta>
              <template #avatar>
                <a-avatar :size="62" :src='avatar_url' v-if='avatar_url'>{{ data.sortName }}</a-avatar>
                <a-avatar :size="62" icon="user" v-else>{{ data.sortName }}</a-avatar>
              </template>
              <template #title>
                <span style="font-size: 1.3rem;color: #000;">{{ data.period }}, {{ data.username }}, 祝您 {{ data.month }} 月工作顺利!</span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </a-col>
        <a-col flex="auto" style="margin: auto;">
          <v-statistics :columns="statisticColumns" :data="statisticData" divider
                        :reverse="false" flex="0 1 140px" justify="space-around"></v-statistics>
        </a-col>
      </a-row>
    </a-card>
    <a-col>
      <a-row type="flex" :gutter="[10, 20]" up>
        <a-col :span="24" left>
          <group-monitor ref="groupMonitor"></group-monitor>
        </a-col>
      </a-row>
      <a-row type="flex" :gutter="[10, 20]" down>
        <a-col flex="auto" :span="16" left>
          <dynamic ref="dynamic"></dynamic>
        </a-col>
        <a-col flex="auto" :span="8" right>
          <ranking-card ref="rankingCard"></ranking-card>
        </a-col>
      </a-row>
    </a-col>
  </a-row> -->
</template>

<style scoped>
.ant-row-flex .ant-col {
  display: flex;
  flex-direction: column;
}
.ant-row-flex[up], .ant-row-flex[down] {
  flex-wrap: nowrap;
}
.ant-row-flex[up] .ant-col > * {
  /*height: 35vh;*/
  height: 350px;
  flex: auto;
}
.ant-row-flex[down] .ant-col > * {
  /*height: 45vh;*/
  min-height: 450px;
  flex: auto;
}
</style>

<script>
import Moment from 'moment'
import GroupMonitor from './GroupMonitor'
import Dynamic from './Dynamic'
import LiveUnderway from "./liveUnderway";
import RankingCard from "./Ranking/rankingCard";
export default {
  components: { GroupMonitor, Dynamic, LiveUnderway, RankingCard },
  computed: {
    data() {
      const moment = Moment(), hours = moment.hours(), period = hours <= 6 ? '晚安' : (hours <= 12 ? '早安' : (hours <= 18 ? '下午好' : '晚上好'))
      return {
        hours,
        period,
        month: moment.month() + 1,
        username: this.$root.user.realname || '',
        sortName: (this.$root.user.realname || '').substr(0, 2),
      }
    }
  },
  data() {
    return {
      statisticColumns: [
        { title: '收藏播主', dataIndex: 'authorCount', click: this.favoritesAuthor, formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '收藏视频', dataIndex: 'awemeCount', click: this.favoritesAweme, formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '已建播主分组', dataIndex: 'authorGroupCount',click: this.favoritesAuthor, formatter: ({ value }) => this.$dict.humanReadable(value) },
        { title: '已建视频分组', dataIndex: 'awemeGroupCount',click: this.favoritesAweme, formatter: ({ value }) => this.$dict.humanReadable(value) }
      ],
      statisticData: {},
      timer: 0,
      avatar_url:sessionStorage.getItem('avatar_url')||undefined
    }
  },
  name: "dashboard",
  methods: {
    load(){
      // this.statisticData = {}
      this.$api.core.DashboardService.countGroupInfo().then(res => {
        this.statisticData = res || {}
      })
    },
    favoritesAuthor(){
      this.$router.push({ name: 'favorites:author'})
    },
    favoritesAweme(){
      this.$router.push({ name: 'favorites:aweme'})
    },
    init(options = {}){
      this.load()
      this.$nextTick(() => {
        this.$refs.groupMonitor.$emit('init')
        this.$refs.dynamic.$emit('init')
        // this.$refs.liveUnderway.$emit('init')
        this.$refs.rankingCard.$emit('init')
      })
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(()=>{
        // this.load()
        this.$router.app.$emit('dashboard-flush')
      },60000)
    },
    skip(){
      let routeData = this.$router.resolve({name:'liveSquare:group',params: { groupId: 'authorGroups'}})
      window.open(routeData.href,"_blank")
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  mounted() {
    this.$on('init', this.init)
  }
}
</script>
