<template>
  <a-list :loading="loading" class="monitor-groups" item-layout="horizontal" :data-source="groups">
    <template #renderItem="row, i">
      <a-list-item :selected="row.id === selId" @click="select(row, i)">
        <a-card :head-style="{ border: 'unset', lineHeight: '0', margin: '-10px -15px -40px' }" :body-style="{ padding: '12px 24px' }">
          <template #extra>
            <a-button shape="circle" icon="minus" size="small" @click="del(row)"></a-button>
          </template>
          <a-list-item-meta :description="`${(row.bizCount || {}).count || 0}个${{ 1: '帐号', 2 : '视频' }[type]}`">
            <template #title>
              <span :title="row.name || '-'">{{ row.name || '-' }}</span>
            </template>
          </a-list-item-meta>
        </a-card>
      </a-list-item>
    </template>
    <div v-if="!groups.length" style="min-height: 200px; display: flex; align-items: center; justify-content: center;">
      暂无分组，<a  @click="add">去添加</a>
    </div>
    <monitor-groups-manage ref="monitorGroupsManage" :type="type" @saved="load"></monitor-groups-manage>
  </a-list>
</template>

<style scoped>
.monitor-groups {
  overflow: hidden;
}
.monitor-groups:hover {
  overflow-y: auto;
}
.ant-list-item {
  cursor: pointer;
  padding: 1px 0;
  border: unset;
}
.ant-list-item-meta-content {
  overflow: hidden;
}
.ant-list-item-meta-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-card {
  width: 100%;
}
.ant-list-item:hover:not([selected]) .ant-card {
  border: 1px solid #1890ff;
}
.ant-list-item[selected] .ant-card {
  border: 1px solid #f59a23;
}
.ant-btn {
  min-width: 18px;
  min-height: 18px;
  width: 18px;
  height: 18px;
  line-height: 0.5;
}
</style>
<script>
import MonitorGroupsManage from '@/components/widgets/monitor-groups-manage';

export default {
  components: { MonitorGroupsManage },
  name: "monitor-groups",
  props: {
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data: () => ({
    loading: false,
    selId: 0,
    groups: [],
    name: '',
    desc: 1
  }),
  methods: {
    select(row = {}, idx) {
      this.selId !== row.id && (this.selId = row.id, this.$emit('select', row))
    },
    del(row) {
      this.$confirm({
        maskClosable: true,
        centered: true,
        content: <span>确定不再监控分组 "<span style="color: red">{row.name}</span>" ?</span>,
        onOk: () => {
          this.$api.FavoriteGroupService.unMonitor(row.id).then(() => {
            this.$message.info('删除成功');
            this.load()
          })
        }
      })
    },
    add(){
      this.$nextTick(() => {
        this.$refs.monitorGroupsManage.$emit("init", {});
      })
    },
    load() {
      this.loading = true
      this.groups = []
      this.$api.FavoriteGroupService.query({ param: { type:this.type,isMonitored: true, isDeleted: false }, limit: 100 }).then(res => {
        this.groups = res.data
        this.groups.reverse()
        this.groups.every(t => t.id !== this.selId) && this.select(this.groups[0], 0)
      }).finally(() => this.loading = false)
    },
  },
  mounted() {
    this.load()
    // this.$on("init", this.load)
  }
}
</script>