<template>
  <a-card title="动态信息" :body-style="{ padding: '5px', height: 'calc(100% - 54px)', display: 'flex', flexDirection: 'column' }">
    <a-row :gutter="[0, 10]" style="margin: 0 10px">
      <a-col>
        <a-radio-group v-model="radio" size="small" @change="onRadioChange">
          <a-radio-button value="all">全部动态</a-radio-button>
          <a-radio-button value="live">直播类</a-radio-button>
          <a-radio-button value="aweme">视频类</a-radio-button>
        </a-radio-group>
      </a-col>
      <a-col>
        <group-tags ref="groupTags" @change="onGroupChange"></group-tags>
      </a-col>
    </a-row>
    <a-row :gutter="[10, 0]" style="display: flex; flex: auto; overflow: hidden">
      <a-col :span="16">
        <calendar ref="calendar" :group-ids="groupIds" :hid-aweme="radio == 'live'" :hid-live="radio == 'aweme'" @change="onDateChange"></calendar>
      </a-col>
      <a-col :span="8">
        <statisics-line ref="statisicsLine" :group-ids="groupIds" :hid-aweme="radio == 'live'" :hid-live="radio == 'aweme'"></statisics-line>
      </a-col>
    </a-row>
  </a-card>
</template>
<style scoped>
</style>
<script>
import GroupTags from './GroupTags'
import Calendar from './Calendar'
import StatisicsLine from './StatisicsLine'

export default {
  components: { GroupTags, Calendar, StatisicsLine },
  data() {
    return {
      radio: 'all',
      groupIds: []
    }
  },
  computed: {
  },
  methods: {
    onRadioChange() {
      this.load()
    },
    onGroupChange(groups = []) {
      this.groupIds = groups.map(t => t.groupId)
      this.load()
    },
    onDateChange(date) {
      this.$nextTick(() => this.$refs.statisicsLine.$emit('init', { record: date}))
    },
    load() {
      this.$nextTick(() => this.$refs.calendar.$emit('init'))
    },
    init(options = {}) {
      this.$nextTick(() => this.$refs.groupTags.$emit('init'))
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
