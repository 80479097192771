<template>
  <v-table ref="videoTable" size="middle" :columns="columns" :data="data" :pagable="false"
           row-key="awemeId" class="table-border-none" :loading="loading">
    <template #orderNum="{$index}">
      <span class="order-num" :le3="$index<3">{{$index+1}}</span>
    </template>
    <template #awemeTitle="{row}">
      <!-- todo -->
      <router-link :to="{ name: 'aweme:analysis', params: { awemeId: row.awemeId } }" target="_blank"
                   :title="row.awemeTitle || '-'">{{row.awemeTitle || '-'}}</router-link>
      <!-- {{row.awemeTitle || '-'}} -->
    </template>
  </v-table>
</template>
<style lang="less" scoped>
.order-num{
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 30px;
  background: #eee;
  display: block;
  line-height: 30px;
}
.order-num[le3="true"]{
  background: #666;
  color: white;
}

</style>
<script>

export default {
  components: { },
  data() {
    return {
      loading: false,
      data: []
    };
  },
  computed: {
    columns() {
      return [
        { title: "", dataIndex: "orderNum", scopedSlots: { customRender: "orderNum" }, width: 30 },
        { title: "", dataIndex: "awemeTitle",scopedSlots: { customRender: "awemeTitle" }, ellipsis: true,width: 140 },
        { title: "评论", dataIndex: "commentCount", ellipsis: true , customRender: this.$dict.humanReadable, width: 40},
        { title: "点赞", dataIndex: "diggCount",ellipsis: true , customRender: this.$dict.humanReadable, width: 40  }
      ]
    }
  },
  methods: {
    load() {
      this.loading = true
      this.$api.core.DashboardService.awemeRanking().then(res => {
        this.data = res.data || []
      }).catch(()=>{
        this.loading = false
      }).finally(()=>{
        this.loading = false
      })
    },
    init(options) {
      this.data = []
      this.load()
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {
    //初始化
    this.$on("init", this.init);
  }
};
</script>
