<template>
  <ve-chart ref="ve-chart" :loading="loading" width="100%" height="100%" :settings="settings" :colors="[color]" :title="title" :series="series"></ve-chart>
</template>

<style scoped>
.ve-chart {
  overflow: hidden;
}
</style>

<script>

export default {
  props: {
    qualifie: {
      type: Number,
      default: 0
    },
    total: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: ''
    },
    yLabel: {
      type: String,
      default: ''
    },
    nLabel: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#1890ff'
    },
    fontSize: {
      type: String,
      default: '14'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    yd() {
      return this.$dict.humanReadable(this.qualifie / (this.total || this.qualifie || 1) * 100)
    },
    nd() {
      return (1000 - this.yd * 10) / 10
    },
    title() {
      return {
        text: this.text,
        subtext: `${this.yd}%`,
        left: 'center',
        top: '35%',
        textStyle: {
          fontSize: 14,
          fontWeight: 400,
          color: '#ababab'
        },
        subtextStyle: {
          fontSize: 18,
          fontWeight: 400,
          color: '#ababab'
        }
      }
    },
    series() {
      const data = []
      this.qualifie && data.push({ name: this.yLabel, value: this.qualifie,
        tooltip: { show: true, formatter: `{b0}: {c0} (${this.yd}%)` }, // selected: true,
      })
      data.push({ value: this.total - this.qualifie, label: { show: false },
        name: this.nLabel,
        itemStyle: {
          normal: { color: '#f0f2f5' },
          emphasis: { color: '#f0f2f5' }
        },
        tooltip: { formatter: `{b0}: {c0} (${this.nd}%)` }
      })
      return [{
        type: 'pie',
        legendHoverLink: false,
        hoverAnimation: false,
        radius: ['70%', '95%'],
        label: {
          show: false,
          position: 'center',
          fontSize: this.fontSize,
          // lineHeight: '14',
          // fontWeight: 'bold',
          color: '#ababab'
        },
        tooltip: {
          show: true,
          position: {left: 'center', bottom: 0}
        },
        data
      }]
    }
  },
  data: () => ({
    settings: { type: 'pie'}
  }),
  methods: {
    resize() {
      this.$refs['ve-chart'].echarts.resize()
    },
    init(options = {}) {
      // this.load();
    }
  },
  mounted() {
    this.$on('init', this.init)
    this.$on('resize', this.resize)
  }
}
</script>
