<template>
  <a-spin :spinning="loading" style="display: inline-block">
    <favorite-group-select ref="selector" v-if="inputVisible" size="small" :type="1" @change="add" @blur="inputVisible = false"
                           default-open allow-clear placeholder="分组搜索" style="width: 100px; margin-right: 8px"></favorite-group-select>
    <a-tag v-else @click="showSelector" style="border-style: dashed; cursor: pointer"><a-icon type="plus" /> 添加分组</a-tag>
    <template v-for="tag in tags">
      <a-tooltip v-if="(tag.favoriteGroup.name || '').length > 8" :key="tag.id" :title="tag.favoriteGroup.name">
        <a-tag :key="tag.id" :color="selId == tag.groupId ? '#108ee9' : 'orange'" :closable="!tag.del" @click="onSelectGroup(tag)" @close="del($event, tag)">
          <span class="tag-text">{{ tag.favoriteGroup.name }}</span>
        </a-tag>
      </a-tooltip>
      <a-tag v-else :key="tag.id" :color="selId == tag.groupId ? '#108ee9' : 'orange'" :closable="!tag.del" @click="onSelectGroup(tag)" @close="del($event, tag)">
        <span class="tag-text">{{ tag.favoriteGroup.name }}</span>
      </a-tag>
    </template>
  </a-spin>
</template>
<style scoped>
.ant-tag {
  /*font-size: 13px;*/
  margin-bottom: 8px;
  cursor: pointer;
}
.ant-tag .tag-text {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /*line-height: 1em;*/
  vertical-align: top;
  display: inline-block;
}
</style>
<script>
import FavoriteGroupSelect from '@/components/widgets/favorite-group-select'

const defaultQuery = {
  orderBys: 'ctime|desc',
  start: 0,
  limit: 50
}
export default {
  components: { FavoriteGroupSelect },
  data: () => ({
    loading: false,
    inputVisible: false,
    tags: [],
    selId: undefined
  }),
  computed: {
  },
  methods: {
    onSelectGroup(tag = {}) {
      this.selId = this.selId == tag.groupId ? undefined : tag.groupId
      this.$emit('change', this.selId ? [tag] : this.tags)
    },
    showSelector() {
      this.inputVisible = true;
      this.$nextTick(function() {
        this.$refs.selector.focus();
      })
    },
    add(groupId) {
      this.loading = true
      this.inputVisible = false
      this.$api.FavoriteDynamicGroupService.save(groupId).then(()=>{
          this.load()
      }).catch(()=>{
        this.loading = false
      })
    },
    del(e, tag) {
      this.loading = true
      this.$set(tag, 'del', true)
      e.preventDefault();
      this.$api.FavoriteDynamicGroupService.delete(tag.id).then(()=>{
        this.load()
      }).catch(() => {
        this.$set(tag, 'del', false)
        this.loading = false
      })
    },
    load() {
      this.loading = true
      const statQuery = Object.assign(_.cloneDeep(defaultQuery))
      this.$api.FavoriteDynamicGroupService.query(statQuery).then(res => {
        this.tags = res.data;
        this.tags.every(t => t.groupId != this.selId) && this.onSelectGroup(this.tags[0])
      }).finally(() => this.loading = false)
    },
    init(options = {}) {
      this.load();
    }
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
