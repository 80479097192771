<template>
  <a-select ref="selector" show-search v-model="currentValue" :placeholder="placeholder" show-arrow :filter-option="false" :loading="loading"
            :size="size" :allow-clear="allowClear" :default-open="defaultOpen" :disabled="disabled"
            @search="search" @change="handleChange" @blur="val => $emit('blur', val)" :default-active-first-option="false">
    <a-select-option v-for="item in options" :key="item.id" :value="item.id" :title="item.name" :disabled="item.disabled">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
  export default {
    name: "favorite-group-select",
    props: {
      value: {
        type: String
        // required: true
      },
      type: {
        type: Number,
        required: true,
        default: 1
      },
      allowClear: {
        type: Boolean
      },
      placeholder: {
        type: String,
        default: '请选择'
      },
      size: {
        type: String
      },
      defaultOpen: {
        type: Boolean
      },
      disabled: {
        type: Boolean
      }
    },
    computed: {
      currentValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    },
    data: () => ({
      loading: false,
      options: []
    }),
    methods: {
      focus() {
        this.$refs.selector.focus()
      },
      handleChange(val) {
        this.$emit('change', val);
      },
      search(keyword) {
        // keyword = (keyword || '').replace(/\[.*\]/g, '')
        // keyword = keyword.replace(/^\s+|\s+$/gm, '')
        keyword = keyword && keyword.trim()
        this.$nextTick(() => {
          (keyword || !this.currentValue) && this.loadData(keyword)
        })
      },
      loadData(keyword) {
        this.options = []
        this.loading = true
        this.$api.FavoriteGroupService.query({
          orderBys: 'id|desc',
          param: { name: keyword, type: this.type, isDeleted: false}
        }).then(res => {
          this.options = res.data
        }).finally(() => {
          this.loading = false
        })
      }
    },
    created() {
      this.search();
    }
  }
</script>
