<template>
  <a-statistic :title="title" :value="defaultFormatter(value)" :formatter="({ value }) => this.$dict.humanReadable(value)" :value-style="{ position: 'relative' }">
    <template #suffix v-if="value != undefined && previous != undefined">
      <span class="statistic-suffix">环比{{chainGrowth(previous, value)}}%<a-icon :type="value > 0 ? 'arrow-up' : 'arrow-down'" :style="{color: value > 0 ? 'red' : 'chartreuse'}"/></span>
    </template>
  </a-statistic>
</template>

<style scoped>
.statistic-suffix {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  bottom: 0;
}
</style>

<script>

export default {
  props: {
    value: {
      type: Number
    },
    previous: {
      type: Number
    },
    title: {
      type: String
    },
    emptyValue: {
      type: String,
      default: '-'
    }
  },
  computed: {
  },
  data: () => ({
  }),
  methods: {
    defaultFormatter(val) {
      return val == undefined ? this.emptyValue : val
    },
    chainGrowth(previous = 0, current = 0) {
      return this.$dict.humanReadable((current - previous) / (Math.abs(previous) || current || 1) * 100)
    }
  },
  mounted() {
  }
}
</script>
