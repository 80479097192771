<template>
  <a-spin :spinning="loading">
    <a-calendar v-model="date" @change="onDateChange" @panelChange="onPanelChange" :disabled-date="disabledDate" class="dynamic-calendar">
      <template #headerRender="{ value, type, onChange, onTypeChange }">
        <a-row type="flex" justify="space-between" class="ant-fullcalendar-header">
          <a-col flex="auto">
          </a-col>
          <a-col>
            <a-select size="small" :dropdownMatchSelectWidth="false" :value="value.year()" class="my-year-select ant-fullcalendar-year-select"
                @change="val => onChange(value.clone().year(val))">
              <a-select-option v-for="(year, i) in years" :key="i" :value="year" class="year-item">{{ year + '年' }}</a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-select size="small" :dropdownMatchSelectWidth="false" :value="value.month()" class="ant-fullcalendar-month-select"
                @change="val => onChange(value.clone().month(parseInt(val, 10)))">
              <a-select-option v-for="(month, i) in months" :key="i" :value="i" class="month-item">{{ month }}</a-select-option>
            </a-select>
          </a-col>
          <a-col>
            <a-button size="small" class="ant-fullcalendar-today" @click="() => onChange(today())">今天</a-button>
          </a-col>
        </a-row>
      </template>
      <template #dateCellRender="value">
        <ul class="events">
          <li v-if="liveGapMap[value.format('YYYY-MM-DD')]" :title="`直播${liveGapMap[value.format('YYYY-MM-DD')]}场`">
            <a-badge color="blue" :text="`${liveGapMap[value.format('YYYY-MM-DD')]}场`"/>
          </li>
          <li v-if="awemeGapMap[value.format('YYYY-MM-DD')]" :title="`视频${awemeGapMap[value.format('YYYY-MM-DD')]}个`">
            <a-badge status="success" :text="`${awemeGapMap[value.format('YYYY-MM-DD')]}个`"/>
          </li>
        </ul>
      </template>
    </a-calendar>
  </a-spin>
</template>
<style scoped>
.ant-fullcalendar-header {
  padding: 0 16px 0 0;
}
.ant-fullcalendar-today {
  margin-left: 8px;
}
.events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
</style>
<style>
.dynamic-calendar .ant-fullcalendar-cell, .dynamic-calendar .ant-fullcalendar-date {
  height: unset;
  padding: 0;
}
.dynamic-calendar .ant-fullcalendar-date .ant-fullcalendar-content {
  height: 52px;
}
.dynamic-calendar .events .ant-badge-status .ant-badge-status-text {
  font-size: 12px;
}
</style>
<script>
import Moment from 'moment'
const defaultQuery = {
  orderBys: 'publishTime|asc',
  dateRanges: {
    dateRange: {
      startDate: undefined,
      endDate: undefined
    }
  },
  authorGroupIds: undefined,
  groupBy: 'publishTime',
  start: 0,
  limit: 100
}
const defaultStatQuery = {
  orderBys: 'publishTime|desc',
  dateRanges: {
    dateRange: {
      startDate: undefined,
      endDate: undefined
    }
  },
  groupIds: undefined,
  groupBy: 'publishTime',
  start: 0,
  limit: 100
}

export default {
  components: {  },
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    hidAweme: {
      type: Boolean
    },
    hidLive: {
      type: Boolean
    }
  },
  data() {
    const moment = Moment(), localeData = moment.localeData(), year = moment.year(), years = [], months = []
    for(let i = 0; i < 12; i++) {
      years.push(year - 11 + i)
      moment.month(i), months.push(localeData.monthsShort(moment))
    }
    return {
      years,
      months,
      date: Moment(),
      loading: false,
      awemeGapMap: {},
      liveGapMap: {}
    }
  },
  computed: {
    dayQuery() {
      return {
        dateRanges: {
          publishTime: {
            startDate: this.date.clone().startOf('month').format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.date.clone().endOf('month').format('YYYY-MM-DD') + ' 23:59:59'
          }
        }
      }
    }
  },
  methods: {
    disabledDate(current) {
      return current && current > Moment().endOf('day')
    },
    today() {
      return Moment()
    },
    onDateChange() {
      this.$nextTick(() => this.$emit('change', this.date))
    },
    onPanelChange() {
      this.$nextTick(() => this.load())
    },
    async awemeGap() {
      const query = Object.assign(_.cloneDeep(defaultQuery), { authorGroupIds: this.groupIds }, this.dayQuery)
      return !this.hidAweme && !this.disabledDate(this.date.clone().startOf('month')) && this.$api.core.AwemeService.queryStatistics(query).then(res => {
        !this.hidAweme && (this.awemeGapMap = Object.fromEntries((res.data || []).map(t => [(t.publishTime || '').substr(0, 10), t.totalCount])))
      })
    },
    async liveGap() {
      const query = Object.assign(_.cloneDeep(defaultStatQuery), { groupIds: this.groupIds }, this.dayQuery)
      return !this.hidLive && !this.disabledDate(this.date.clone().startOf('month')) && this.$api.core.LiveService.queryStatistics(query).then(res => {
        !this.hidLive && (this.liveGapMap = Object.fromEntries((res.data || []).map(t => [(t.publishTime || '').substr(0, 10), t.liveCount])))
      })
    },
    load() {
      this.awemeGapMap = {}
      this.liveGapMap = {}
      if (this.groupIds.length) {
        this.loading = true
        Promise.all([this.awemeGap(), this.liveGap()]).finally(() => this.loading = false)
      }
    },
    init(options = {}) {
      this.onDateChange()
      this.load()
    }
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
