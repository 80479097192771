<template>
  <a-timeline style="margin: 10px 0">
    <div class="timeline-title" style="margin-bottom: 15px;display: flex">
      <span style="flex: auto">{{formatDate}}</span>
      <a-badge color="blue" text="直播"/>
      <a-badge status="success" text="视频"/>
    </div>
    <a-spin :spinning="loading">
    <a-timeline-item v-if="!hidLive && liveStatistics.length">
      <span><span class="data-text">{{liveTotal.authorCount}}</span> 个播主, <span class="data-text">{{liveTotal.liveCount}}</span> 场直播</span>
      <a-list size="small" :data-source="liveStatistics">
        <template #renderItem="row, i">
          <a-list-item>
            <a-list-item-meta>
              <template #avatar>
                <a-avatar :size="24" icon="user" :src="row.author.avatar"></a-avatar>
              </template>
              <template #title>
                <div class="data-label nickname" :title="row.author.nickname">
                  <router-link class="data-label" :to="{ name: 'author:analysis', params: { authorId: row.author.authorId }, query: { activeTab: 'liveList' } }" target="_blank">{{ row.author.nickname }}</router-link>
                </div>
                <div class="data-info" :title="`累计进行 ${ row.liveCount || 0 } 场直播 ${ row.livingCount ? '正在直播' : '' }`">
                  <div class="data-label">累计进行
                    <span class="data-text">{{ row.liveCount }}</span> 场直播
                    <!-- TODO 屏蔽监控页面入口 -->
                    <!-- <router-link v-if="row.livingCount && row.latest && false" class="data-text" :to="{ name: 'liveMonitor:room', params: {roomId: (row.latest || {}).roomId }, query: { author_id: row.author.authorId} }" target="_blank">正在直播</router-link> -->
                    <router-link v-if="row.livingCount && row.latest && false" class="data-text">正在直播</router-link>
<!--                    <span v-if="row.livingCount" class="data-text">正在直播</span>-->
                  </div>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-timeline-item>
    <a-timeline-item v-if="!hidLive && liveStatistics.length"></a-timeline-item>
    <template v-if="!hidLive && liveStatistics.length && !hidAweme && awemeStatistics.length">
      <br v-for="i in 4" :key="i">
    </template>
    <a-timeline-item v-if="!hidAweme && awemeStatistics.length" color="green">
      <span><span class="data-text">{{awemeTotal.authorCount}}</span> 个播主, <span class="data-text">{{awemeTotal.awemeCount}}</span> 个作品</span>
      <a-list size="small" :data-source="awemeStatistics">
        <template #renderItem="row, i">
          <a-list-item>
            <a-list-item-meta>
              <template #avatar>
                <a-avatar :size="24" icon="user" :src="row.author.avatar"></a-avatar>
              </template>
              <template #title>
                <div class="data-label nickname" :title="row.author.nickname">
                  <router-link class="data-label" :to="{ name: 'author:analysis', params: { authorId: row.author.authorId } }" target="_blank">{{ row.author.nickname }}</router-link>
                </div>
                <div class="data-info" :title="`发布了 ${ row.totalCount || 0 } 个视频 }`">
                  <div class="data-label"> 发布了 <span class="data-text">{{ row.totalCount }}</span> 个视频</div>
                </div>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
    </a-timeline-item>
    <a-timeline-item v-if="!hidAweme && awemeStatistics.length" color="green"></a-timeline-item>
    </a-spin>
  </a-timeline>
</template>
<style scoped>
.ant-timeline-item {
  padding: unset;
}
.timeline-title .ant-badge {
  margin: 0 0 0 10px;
}
.data-label {
  color: #000;
  display: block;
}
.data-text {
  color: #1890ff;
  font-weight: bold;
}
.nickname {
  flex: 0 1 auto;
  margin-right: 5px;
}
.data-info {
  /*flex: 0 0 100px;*/
  max-width: 80%;
}
.ant-list {
  max-height: 150px;
  overflow-y: hidden;
}
.ant-list:hover {
  overflow-y: auto;
}
.ant-list-item {
  border: unset;
  display: block;
}
.ant-list-item-meta-avatar {
  margin-right: 4px;
}
.ant-list-item-meta-title {
  display: flex;
  font-size: 12px;
}
.ant-list-item-meta-content, .ant-list-item-meta-title {
  overflow: hidden;
}
.ant-list-item-meta-title .data-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<script>
const defaultAwemeQuery = {
  orderBys: 'authorId|asc',
  dateRanges: {
    publishTime: {
      startDate: undefined,
      endDate: undefined
    }
  },
  authorGroupIds: undefined,
  groupBy: 'authorId',
  start: 0,
  limit: 100
}
const defaultLiveQuery = {
  orderBys: 'authorId|desc',
  dateRanges: {
    publishTime: {
      startDate: undefined,
      endDate: undefined
    }
  },
  groupIds: undefined,
  groupBy: 'authorId',
  start: 0,
  limit: 100
}
export default {
  components: { },
  props: {
    groupIds: {
      type: Array,
      default: () => []
    },
    hidAweme: {
      type: Boolean
    },
    hidLive: {
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    inputVisible: false,
    timestamp: 0,
    date: undefined,
    awemeTotal: {},
    liveTotal: {},
    awemeStatistics: [],
    liveStatistics: []
  }),
  computed: {
    formatDate() {
      return this.date ? this.date.format('YYYY年MM月DD日') : ''
    },
    dayQuery() {
      return {
        dateRanges: {
          publishTime: {
            startDate: this.date.format('YYYY-MM-DD') + ' 00:00:00',
            endDate: this.date.format('YYYY-MM-DD') + ' 23:59:59'
          }
        }
      }
    }
  },
  methods: {
    async awemeStatistic(timestamp) {
      const query = Object.assign(_.cloneDeep(defaultAwemeQuery), { authorGroupIds: this.groupIds }, this.dayQuery)
      return !this.hidAweme && this.$api.core.AwemeService.queryStatistics(query).then(res => {
        if (timestamp != this.timestamp) return
        this.awemeStatistics = res.data || []
        res.data.forEach(t => t.author || (t.author = {}))
        this.awemeTotal = {
          authorCount: (res.data || []).length,
          awemeCount: res.data.length && res.data.map(t => t.totalCount || 0).reduce((a, b) => a + b)
        }
      })
    },
    async liveStatistic(timestamp) {
      const query = Object.assign(_.cloneDeep(defaultLiveQuery), { groupIds: this.groupIds }, this.dayQuery)
      return !this.hidLive && this.$api.core.LiveService.queryStatistics(query).then(res => {
        if (timestamp != this.timestamp) return
        this.liveStatistics = res.data || []
        res.data.forEach(t => t.author || (t.author = {}))
        this.liveTotal = {
          authorCount: (res.data || []).length,
          liveCount: res.data.length && res.data.map(t => t.liveCount || 0).reduce((a, b) => a + b)
        }
      })
    },
    load() {
      const timestamp = this.timestamp = Date.now()
      this.awemeStatistics = [], this.awemeTotal = {}
      this.liveStatistics = [], this.awemeTotal = {}
      if (this.groupIds.length) {
        this.loading = true
        Promise.all([this.awemeStatistic(timestamp), this.liveStatistic(timestamp)]).finally(() => this.loading = false)
      }
    },
    init(options = {}) {
      if(options.record){
        this.date = options.record
        this.load()
      }
    }
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
