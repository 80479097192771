<!--表单-->
<template slot="footer">
  <a-modal v-model="visible" :title="`监控${type===1 ? '播主' : '视频'}分组`" :bodyStyle="{maxHeight:'400px',overflow:'auto'}">
    <a-form-model ref="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :bodyStyle="{maxHeight:'400px',overflow:'auto'}">
      <a-spin :spinning="spinning" :style="{minHeight:'200px'}" size="large">
        <a-checkbox-group v-model="groupIds">
          <a-row style="padding: 5px 0 5px" v-for="item in groups" :key="item.id">
            <a-checkbox :value="item.id">{{ item.name }}</a-checkbox>
          </a-row>
        </a-checkbox-group>
      </a-spin>
    </a-form-model>
    <template #footer>
      <a-button style="float: left" type="link" icon="folder-add" @click="add">新建分组</a-button>
      <a-button @click="visible = false">取消</a-button>
      <a-button type="primary" :loading="loading" @click="batchMonitor">确定</a-button>
      <group-form v-model="groupFormVisible" ref="GroupForm" @saved="onGroupSaved" :type="type"></group-form>
    </template>
  </a-modal>
</template>
<style scoped>
</style>
<script>

import GroupForm from "@/components/widgets/group-form";

export default {
  components: { GroupForm },
  props: {
    type: {
      type: Number,
      required: true,
      default: 1
    }
  },
  data: () => ({
    visible: false,
    groupFormVisible: false,
    groups: [],
    groupIds: [],
    loading: false,
    spinning:false
  }),
  computed: {},
  methods: {
    add() {
      this.groupFormVisible = true;
      this.$nextTick(() => {
        this.$refs.GroupForm.$emit("init", {})
      })
    },
    onGroupSaved() {
      this.load()
    },
    batchMonitor() {
      this.loading = true;
      this.$api.FavoriteGroupService.batchMonitor(this.type, this.groupIds).then(() => {
        this.$message.info("操作成功！")
        this.visible = false
        this.$emit('saved')
      }).finally(() => {
        this.loading = false
      })
    },
    load() {
      this.spinning = true
      this.groups = [];
      this.groupIds = []
      this.$api.FavoriteGroupService.query({ param: { type: this.type,isDeleted: false }, limit: 100 }).then(res => {
        this.groupIds = (res.data || []).filter(t => t.isMonitored).map(t => t.id)
        this.groups = res.data;
        this.groups.reverse()
        this.spinning = false
      })
    },
    init(options = {}) {
      this.visible = true
      this.load()
    }
  },
  beforeCreate() {

  },
  created() {
  },
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
