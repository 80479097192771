<!--表单-->
<template>
  <a-card :tab-list="tabList" :active-tab-key="activeTab" :body-style="{ padding: '5px', height: 'calc(100% - 54px)', display: 'flex', flexDirection: 'column' }" @tabChange="onTabChange">
    <template #tabBarExtraContent>
      <router-link :to="{ name: `favorites:${activeTab}` }">查看更多</router-link>
    </template>
    <author-monitor :style="activeTab == 'author' ? {} : { display: 'none' }" ref="author"></author-monitor>
    <aweme-monitor :style="activeTab == 'aweme' ? {} : { display: 'none' }" ref="aweme"></aweme-monitor>
  </a-card>
</template>
<style scoped>
</style>
<script>
import AuthorMonitor from './AuthorMonitor'
import AwemeMonitor from './AwemeMonitor'

export default {
  components: { AuthorMonitor, AwemeMonitor },
  data() {
    return {
      tabList: [
        { key: 'author', tab: '播主分组监控' },
        { key: 'aweme', tab: '视频分组监控' }
      ],
      loading: false,
      activeTab: 'author'
    }
  },
  computed: {
  },
  methods: {
    onTabChange(activeTab) {
      this.activeTab = activeTab
      this.$nextTick(() => this.load())
    },
    load() {
      this.$refs[this.activeTab].$emit('init');
    },
    init(options = {}) {
      this.onTabChange(this.activeTab)
    }
  },
  created() {
  },
  mounted() {
    this.$on('init', this.init);
  }
};
</script>
