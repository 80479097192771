<template>
  <a-card :tab-list="tabList" :active-tab-key="activeTab"
          @tabChange="onTabChange" :bodyStyle="{padding: '5px',
          height: 'calc(100% - 54px)',}">
    <live-out ref="liveOut"  :style="activeTab === 'liveOut' ? {} : { display: 'none'}"></live-out>
    <video-ranking ref="videoRanking" :style="activeTab === 'videoRanking' ? {} : { display: 'none' }"
    ></video-ranking>
  </a-card>
</template>
<style scoped>
</style>
<script>
import LiveOut from './liveOut'
import VideoRanking from "./videoRanking";
export default {
  components:{
    LiveOut,VideoRanking,
  },
  props: {

  },
  data() {
    return {
      tabList: [
        { key: 'liveOut', tab: '直播排行' },
        { key: 'videoRanking', tab: '视频排行' }
      ],
      activeTab: 'liveOut'
    };
  },
computed: {
},
  methods: {
    onTabChange(activeTab) {
      this.activeTab = activeTab
      this.$nextTick(() => this.load())
    },
    load(){
      this.$refs[this.activeTab].$emit('init');
    },
    init(options = {}) {
      this.onTabChange(this.activeTab)
    }
  },
  created() {
  },
  mounted() {
    this.load()
    this.$on('init',this.init)
  }
};
</script>
