<template>
  <dashboard ref="dashboard"></dashboard>
</template>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<script>
import Dashboard from '@/pages/app/dashboard'

export default {
  components: { Dashboard },
  data() {
    return {
    }
  },
  methods: {
    init(options) {
      this.$refs.dashboard.$emit('init', options)
    }
  },
  mounted() {
    this.$on('init', this.init)
  }
}
</script>
