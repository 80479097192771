<template>
  <a-card title="进行中的直播" :bordered="false"
          :bodyStyle="{padding: '0px',
          height: 'calc(100% - 50px)', display: 'flex',
          flexDirection: 'column',overflow:'auto'}" >
        <a-list class="live-underway" item-layout="horizontal" :data-source="liveGroups" :split="false" :loading="loading">
          <template #renderItem="row, i">
            <a-list-item>
              <a-list-item-meta @click="liveSquare(row.id)">
                <template #avatar>
                  <a-icon type="team"  style="font-size: 20px"/>
                </template>
                <template #title>
                  <span :title="row.name || '-'">{{ row.name || '-' }}</span>
                </template>
              </a-list-item-meta>
              <div style="margin-right: 60px;min-width: 50px;padding: 0px 0px 4px 16px">{{row.bizCount.count || 0}}场直播</div>
            </a-list-item>
          </template>
        </a-list>
  </a-card>
</template>

<style scoped>
.ant-list-item {
  padding-left: 16px;
}
.ant-list-item:after{

}
.ant-list-item:hover {
  background: #e6f7ff;
}
.ant-list-item-meta{
  margin-left: 20px;
  cursor: pointer;
}

.ant-list-item-meta, .ant-list-item-meta-content {
  overflow: hidden;
}
.ant-list-item-meta-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<script>

export default {
  components: { },
  name: "live_underway",
  props: {
   /* value: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number,
      required: true,
      default: 1
    }*/
  },
  data() {
    return {
      liveGroups:[],
      loading:false,
      name:undefined
    }
  },
  computed: {

  },
  methods: {
     load() {
      this.loading = true
      this.$api.core.DashboardService.liveGroupQuery().then( res =>{
        this.liveGroups = res
        this.liveGroups.reverse()
      }).catch(()=>{
        this.loading = false
      }).finally(()=>{
        this.loading = false
      })
    },
    liveSquare(id){
      return this.$router.push({ name: 'liveSquare:group', params: { groupId: id}})
    },
    init(){
      this.load()
    }
  },
  mounted() {
    this.$on('init', this.init)
    this.$router.app.$on('dashboard-flush', this.init)
  },
  beforeDestroy() {
    this.$router.app.$off('dashboard-flush', this.init)
  }
}
</script>
