<template>
  <a-row :gutter="[10, 0]" style="display: flex; flex: auto; overflow: hidden">
    <a-col :span="8" style="display: flex; flex-direction: column">
      <monitor-groups ref="monitorGroups" :type="2" @select="onSelect" style="height: 100%;"></monitor-groups>
      <a-button type="dashed" icon="plus" style="margin-top: 5px" @click="$refs.monitorGroups.add()">添加</a-button>
    </a-col>
    <a-col :span="16">
      <a-card size="small" title="活动数据" :bordered="false" :head-style="{ border: 'unset' }" :body-style="{ display: 'flex', padding: 'unset', height: 'calc(100% - 37px)' }" style="height: 40%">
        <statistic title="播放量日增长" :value="data.gap.gapPlayVolume" :previous="data.gap.beforeGapPlayVolume"></statistic>
        <statistic title="评论量日增长" :value="data.gap.gapCommentCount" :previous="data.gap.beforeGapCommentCount"></statistic>
      </a-card>
      <a-card size="small" :bordered="false" :head-style="{ border: 'unset' }" :body-style="{ display: 'flex', padding: 'unset', height: 'calc(100% - 37px)' }" style="height: 60%">
        <template #title>
          视频数据 <a-tooltip placement="rightTop" title="爆款定义: 点赞大于等于1000或播放大于等于十万的视频即为爆款。"><a-icon type="question-circle" /></a-tooltip>
        </template>
        <statistic title="爆款数量" :value="data.statistic.hotCount"></statistic>
        <pie-chart ref="pie" text="爆款率" yLabel="爆款数量" nLabel="非爆款数量" :qualifie="data.statistic.hotCount" :total="awemeCount" :loading="loading"></pie-chart>
      </a-card>
    </a-col>
  </a-row>
</template>

<style scoped>
.ant-statistic, .ve-chart {
  flex: 1;
  text-align: center;
}
.anticon {
  cursor: pointer;
}
</style>

<script>
import MonitorGroups from '@/components/widgets/monitor-groups'
import Statistic from './Statistic'
import PieChart from './PieChart'

export default {
  components: { MonitorGroups, Statistic, PieChart },
  data: () => ({
    loading: false,
    data: {
      statistic: {},
      gap: {}
    },
    groupId: '',
    awemeCount: 0,
    timestamp: 0
  }),
  methods: {
    onSelect(group = {}) {
      this.groupId = group.id || 0
      this.load().then(() => this.awemeCount = (group.bizCount || {}).count || 0)
    },
    resize() {
      this.$refs['pie'].resize()
    },
    async load() {
      const timestamp = this.timestamp = Date.now()
      this.data = { statistic: {}, gap: {} }, this.awemeCount = 0
      if (this.groupId) {
        this.loading = true
        return this.$api.core.DashboardService.getAwemeMonitor(this.groupId).then(res => {
          timestamp == this.timestamp && (this.data = res)
        }).finally(() => this.loading = false)
      }
    },
    init(options = {}) {
      // this.groupId && this.load();
      this.resize()
    }
  },
  mounted() {
    this.$on('init', this.init);
  }
}
</script>
