<template>
  <a-row :gutter="[10, 0]" style="display: flex; flex: auto; overflow: hidden">
    <a-col :span="8" style="display: flex; flex-direction: column">
      <monitor-groups ref="monitorGroups" :type="1" @select="onSelect" style="height: 100%;"></monitor-groups>
      <a-button type="dashed" icon="plus" style="margin-top: 5px" @click="$refs.monitorGroups.add()">添加</a-button>
    </a-col>
    <a-col :span="16">
      <a-card size="small" title="本周活动数据" :bordered="false" :head-style="{ border: 'unset' }" :body-style="{ display: 'flex', padding: 'unset', height: 'calc(100% - 37px)' }" style="height: 40%">
        <statistic title="累计直播场次" :value="data.live.liveCount" :previous="data.live.beforeLiveCount"></statistic>
        <statistic title="作品发布量" :value="data.aweme.totalCount" :previous="data.aweme.beforeTotalCount"></statistic>
      </a-card>
      <a-card size="small" :bordered="false" :head-style="{ border: 'unset' }" :body-style="{ display: 'flex', padding: 'unset', height: 'calc(100% - 37px)' }" style="height: 60%">
        <template #title>
          主播参与情况 <a-tooltip placement="rightTop">
          <template #title>直播参与率: 有直播记录的播主数量与分组内播主数量之比<br>作品发布率: 有视频发布记录的播主数量与分组内播主数量之比</template>
          <a-icon type="question-circle" /></a-tooltip>
        </template>
        <pie-chart ref="livePie" text="直播参与率" yLabel="参与播主" nLabel="未参与播主" :qualifie="data.live.participateCount" :total="authorCount" :loading="loading"></pie-chart>
        <pie-chart ref="gapPie" color="#6ad389" text="作品发布率" yLabel="参与播主" nLabel="未参与播主" :qualifie="data.aweme.participateCount" :total="authorCount" :loading="loading"></pie-chart>
      </a-card>
    </a-col>
  </a-row>
</template>

<style scoped>
.ant-statistic, .ve-chart {
  flex: 1;
  text-align: center;
}
.anticon {
  cursor: pointer;
}
</style>

<script>
import MonitorGroups from '@/components/widgets/monitor-groups'
import Statistic from './Statistic'
import PieChart from './PieChart'

export default {
  components: { MonitorGroups, Statistic, PieChart },
  data: () => ({
    loading: false,
    data: {
      aweme: {},
      live: {}
    },
    groupId: '',
    authorCount: 0,
    timestamp: 0
  }),
  methods: {
    onSelect(group = {}) {
      this.groupId = group.id || 0
      this.load().then(() => this.authorCount = (group.bizCount || {}).count || 0)
    },
    resize() {
      this.$refs['livePie'].resize()
      this.$refs['gapPie'].resize()
    },
    async load() {
      const timestamp = this.timestamp = Date.now()
      this.data = { aweme: {}, live: {} }, this.authorCount = 0
      if (this.groupId) {
        this.loading = true
        return this.$api.core.DashboardService.getAuthorMonitor(this.groupId).then(res => {
          timestamp == this.timestamp && (this.data = res)
        }).finally(() => this.loading = false)
      }
    },
    init(options = {}) {
      // this.groupId && this.load();
      this.resize()
    }
  },
  mounted() {
    this.$on('init', this.init);
  }
}
</script>
