<template>
    <v-table ref="videoTable" size="middle" :columns="columns" :data="data" :pagable="false"
             row-key="roomId" class="table-border-none" :loading="loading"  >
      <template #orderNum="{$index,row}">
        <span class="order-num" :le3="$index<3">{{$index+1}}</span>
      </template>
      <template #rowTitle="{row}">
        <router-link :to="{ name: 'live:analysis', params: { roomId: row.roomId}, query: {author_id: row.authorId } }" target="_blank"
                     :title="row.title || `${(row.author || {}).nickname || '-'}正在直播`">{{row.title || `${(row.author || {}).nickname || '-'}正在直播`}}</router-link>
      </template>
    </v-table>
</template>
<style lang="less" scoped>
.order-num{
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 30px;
  background: #eee;
  display: block;
  line-height: 30px;
}
.order-num[le3="true"]{
  background: #666;
  color: white;
}


</style>
<script>

export default {
  components: { },
  props:{

  },
  data() {
    return {
      loading: false,
      data: [],
    };
  },
  computed: {
    columns() {
      return [
        { title: "", dataIndex: "orderNum", scopedSlots: { customRender: "orderNum" }, width: 30},
        { title: "", dataIndex: "title",scopedSlots: { customRender: "rowTitle" }, ellipsis: true,width: 140 },
        { title: "观看人数", dataIndex: "statisticsTotalWatchUserCount", ellipsis: true , customRender: this.$dict.humanReadable, width: 60},
        { title: "评论", dataIndex: "statisticsTotalCommentCount", ellipsis: true ,customRender: this.$dict.humanReadable, width: 40}
      ]
    }
  },
  methods: {
    load() {
      this.loading = true
      this.$api.core.DashboardService.liveRanking().then(res => {
        this.data = res.data || []
      }).catch(()=>{
        this.loading = false
      }).finally(()=>{
        this.loading = false
      })
    },
    init(options={}) {
      this.data=[]
      this.load()
    }
  },
  mounted() {
    //初始化
    this.$on("init", this.init);
  }
};
</script>
